import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { ScanCameraPageRoutingModule } from './scan-camera-routing.module';

import { ScanCameraPage } from './scan-camera.page';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    ScanCameraPageRoutingModule
  ],
  declarations: [ScanCameraPage]
})
export class ScanCameraPageModule {}
