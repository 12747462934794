import { Injectable } from '@angular/core';
import { GlobalService } from './global.service';
import { HttpClient } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class AjaxService {

  constructor(
    private snackBar: MatSnackBar,
    private globals: GlobalService,
    private http: HttpClient
) { }

  get(cmd: string, par: {} = {} ) {
    return new Promise((resolve, reject) => {
      this.http.post(
        this.globals.baseurl + 'api/voorraad2019.php', {
        compname: this.globals.compname,
        username: this.globals.username,
        password: this.globals.password,
        session: this.globals.session,
        pcnr: this.globals.uuid,
        app: this.globals.App,
        ver: this.globals.Version,
        dev: this.globals.devInfo,
        cmd,
        par // a key-value in par will overwrite above key/values
      }).subscribe({
        next: async data => {
                resolve(data);
            },
        error: async error => {
                console.log('ajax-error:', error);
                if (error && error.status) {
                    this.snackBar.open('Probleem op server: '+error.status, 'OK', {
                        duration: 3000
                    });
                } else if (error && error.hasOwnProperty('status') && error.status === 0) {
                    this.snackBar.open('Probleem met uw verbinding', 'OK', {
                        duration: 3000
                    });
                }
                reject(error);
            }
        }
      );
    });
  }
}
