import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
    {
        path: '',
        redirectTo: 'login',
        pathMatch: 'full'
    },
    { path: 'home',
        loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
    },
    { path: 'login', loadChildren: () => import('./login/login.module').then(m => m.LoginPageModule) },
    { path: 'about', loadChildren: () => import('./about/about.module').then(m => m.AboutPageModule) },
    { path: 'afboeking', loadChildren: () => import('./afboeking/afboeking.module').then(m => m.AfboekingPageModule) },
    { path: 'settings', loadChildren: () => import('./settings/settings.module').then(m => m.SettingsPageModule) },
    { path: 'overboeking', loadChildren: () => import('./overboeking/overboeking.module').then(m => m.OverboekingPageModule) },
    { path: 'object', loadChildren: () => import('./objecten/objecten.module').then(m => m.ObjectenPageModule) },
    {
        path: 'object/:objnr',
        loadChildren: () => import('./objecten/objecten.module').then( m => m.ObjectenPageModule)
    },
    {
        path: 'orders',
        loadChildren: () => import('./orders/orders.module').then( m => m.OrdersPageModule)
    },
    {
        path: 'uren',
        loadChildren: () => import('./uren/uren.module').then( m => m.UrenPageModule)
    },
    {
        path: 'record-form',
        loadChildren: () => import('./record-form/record-form.module').then( m => m.RecordFormPageModule)
    },
    {
        path: 'voorraadmutatie',
        loadChildren: () => import('./voorraadmutatie/voorraadmutatie.module').then( m => m.VoorraadmutatiePageModule)
    },
    {
        path: 'map',
        loadChildren: () => import('./map/map.module').then( m => m.MapPageModule)
    },
    {
        path: 'bedrijven',
        loadChildren: () => import('./bedrijven/bedrijven.module').then( m => m.BedrijvenPageModule)
    },
    {
        path: 'bedrijf/:bedrid',
        loadChildren: () => import('./bedrijven/bedrijven.module').then( m => m.BedrijvenPageModule)
    },
    {
        path: 'afboeking2',
        loadChildren: () => import('./afboeking2/afboeking2.module').then( m => m.Afboeking2PageModule)
    },
    {
        path: 'form/:formnr',
        loadChildren: () => import('./form/form.module').then( m => m.FormPageModule)
    },
    {
        path: 'form/:formnr/:ordnr',
        loadChildren: () => import('./form/form.module').then( m => m.FormPageModule)
    },
    { path: '**', redirectTo: 'login' },   {
    path: 'scan-camera',
    loadChildren: () => import('./scan-camera/scan-camera.module').then( m => m.ScanCameraPageModule)
  },
  {
    path: 'map',
    loadChildren: () => import('./map/map.module').then( m => m.MapPageModule)
  },

// page not found
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {}
