<ion-header style="background-color: white !important;">
  <ion-toolbar>
    <ion-buttons slot="start">
        <ion-button (click)="doCancel()">
            <ion-icon name="close-circle" style="color: red;"></ion-icon>
        </ion-button>
    </ion-buttons>
    <ion-title>scan barcode of QR-code</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
    <div class="horline">
   </div>
</ion-content>
