import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { BarcodeScanner } from '@capacitor-community/barcode-scanner';

@Component({
  selector: 'app-scan-camera',
  templateUrl: './scan-camera.page.html',
  styleUrls: ['./scan-camera.page.scss'],
})
export class ScanCameraPage implements OnInit {

    constructor(
        public viewCtrl: ModalController,
    ) { }

    ngOnInit() {
    }

    async ionViewWillEnter() {
        const permission = await BarcodeScanner.checkPermission({force: true});
        if (permission.granted) {
            BarcodeScanner.hideBackground();
            document.querySelector('body').classList.add('scanner-active');
            const barcodeData = await BarcodeScanner.startScan();
            document.querySelector('body').classList.remove('scanner-active');
            if (barcodeData.hasContent) {
                this.viewCtrl.dismiss({code: barcodeData.content});
            } else {
                this.viewCtrl.dismiss();
            }
        } else {
            this.viewCtrl.dismiss();
        }
    }

    ionViewWillLeave() {
        document.querySelector('body').classList.remove('scanner-active');
    }

    doCancel() {
        document.querySelector('body').classList.remove('scanner-active');
        this.viewCtrl.dismiss();
    }
}
