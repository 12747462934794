<ion-header [hidden]="scanCamera">
  <ion-toolbar>
    <ion-title>{{tableDescr}} invoer</ion-title>
  </ion-toolbar>
</ion-header>
<ion-content [hidden]="scanCamera">
    <table>
        <tr *ngFor="let q of recordFields; let i=index; trackBy:customTrackBy">
            <td *ngIf="q.typ!=='-'" class="tag">{{q.descr}}</td>
            <td>
                <mat-checkbox *ngIf="q.typ=='C'" [(ngModel)]="recordFields[i].value" (input)='evalForm()'>
                </mat-checkbox>
                <mat-form-field *ngIf="q.typ=='COMBO'">
                    <input matInput [matAutocomplete]="auto" [(ngModel)]="recordFields[i].value"
                        (input)='comboChanged($any($event.target).value, i)'
                        [ngClass]="{'invalid': !q.valid}"
                        (input)='evalForm()'
                        [disabled]="!recordFields[i].EditCond"
                    >
                    <mat-autocomplete #auto="matAutocomplete"
                        panelWidth='400px'
                        (optionSelected)='comboChanged($event.option.value, i)'
                    >
                        <mat-option *ngFor="let lst of recordFields[i].choices" [value]="lst.ID">
                            <span>{{lst.ID}}: {{lst.txt}}</span>
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
                <mat-form-field *ngIf="q.typ=='D'">
                    <input matInput
                        [matDatepicker]="picker2"
                        [min]="recordFields[i].minDate"
                        [(ngModel)]="recordFields[i].value"
                        [disabled]="!recordFields[i].EditCond"
                        (input)='evalForm()'>
                    <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                    <mat-datepicker #picker2></mat-datepicker>
                </mat-form-field>
                <div *ngIf="q.typ=='HTML'" style="border: 1px solid #695656; border-width: thin;">
                    <ckeditor [editor]="Editor" [(ngModel)]="recordFields[i].value" [disabled]="!recordFields[i].EditCond"></ckeditor>
                </div>
                <mat-form-field *ngIf="q.typ=='L'">
                    <mat-select [(value)]="recordFields[i].value" (selectionChange)='evalForm()' [disabled]="!recordFields[i].EditCond">
                        <mat-option *ngFor="let lst of recordFields[i].choices" [value]="lst.ID">
                            {{lst.txt}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field *ngIf="q.typ=='N'">
                    <input type='number' matInput [(ngModel)]="recordFields[i].value" (input)='evalForm()' [disabled]="!recordFields[i].EditCond">
                </mat-form-field>
                <mat-form-field *ngIf="q.typ=='R'">
                    <input matInput [(ngModel)]="recordFields[i].value" (input)='evalForm()' [disabled]="!recordFields[i].EditCond">
                    <mat-error *ngIf="q.msg">{{q.msg}}</mat-error>
                </mat-form-field>
                <mat-form-field *ngIf="q.typ=='T'">
                    <textarea matInput rows="5" [(ngModel)]="recordFields[i].value" [disabled]="!recordFields[i].EditCond"></textarea>
                </mat-form-field>
                <mat-form-field *ngIf="q.typ=='TIME'">
                    <input type="time" step="60" matInput [(ngModel)]="recordFields[i].value" (input)='evalForm()' [disabled]="!recordFields[i].EditCond">
                    <mat-error *ngIf="q.msg">{{q.msg}}</mat-error>
                </mat-form-field>
                <span *ngIf="q.msg" class='invalid'><br>{{q.msg}}</span>
            </td>
            <td>
                <ion-button (click)="doScan(i)" *ngIf="!global.optHideScanBtn && q.btn=='SCAN'">
                    <ion-icon name="barcode"></ion-icon>
                </ion-button>
            </td>
        </tr>
    </table>
</ion-content>
<ion-footer>
    <ion-toolbar>
        <ion-grid style="position: fixed; bottom: 0px; right: 0px;">
            <ion-row>
                <button mat-button color="primary" (click)="doCancel()">
                    <mat-icon>cancel</mat-icon> Cancel
                </button>
                <button mat-raised-button color="primary" (click)="doSubmit()" [disabled]="!formValid">
                    <mat-icon>check</mat-icon> OK
                </button>
            </ion-row>
        </ion-grid>
    </ion-toolbar>
</ion-footer>
