<ion-app>
    <ion-split-pane when="xl" content-id="main-content">
        <ion-menu content-id="main-content" type="overlay" [disabled]="!(global.isLoggedIn)">
            <ion-header>
                <ion-toolbar>
                    <ion-title>Lisaas App</ion-title>
                </ion-toolbar>
            </ion-header>
            <ion-content id="my-content">
                <ion-list>
                    <ion-menu-toggle auto-hide="false" *ngFor="let p of appPages">
                        <ion-item routerDirection="root" [routerLink]="[p.url]" *ngIf="!p.hide" lines="none"
                            detail="false" routerLinkActive="selected">
                            <ion-icon slot="start" [name]="p.icon"></ion-icon>
                            <ion-label>
                                {{p.title}}
                            </ion-label>
                        </ion-item>
                    </ion-menu-toggle>
                </ion-list>
            </ion-content>
        </ion-menu>
        <ion-router-outlet id="main-content"></ion-router-outlet>
    </ion-split-pane>
</ion-app>