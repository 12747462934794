import { Component } from '@angular/core';

import { Platform } from '@ionic/angular';
import { Router } from '@angular/router';
import { GlobalService } from './global.service';

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss']
})
export class AppComponent {
    public appPages = [
        {
            title: 'Artikel info',
            url: '/home',
            icon: 'home',
            hide: false
        },
        {
            title: 'Afboeking',
            url: '/afboeking',
            icon: 'cart',
            hide: false
        },
        {
            title: 'Afboeking +/-',
            url: '/afboeking2',
            icon: 'barcode-outline',
            hide: false
        },
        {
            title: 'Overboeking',
            url: '/overboeking',
            icon: 'code-working',
            hide: false
        },
        {
            title: 'Voorraad',
            url: '/voorraadmutatie',
            icon: 'grid-outline',
            hide: false
        },
        {
            title: 'Kaart',
            url: '/map',
            icon: 'globe-outline',
            hide: false
        },
        {
            title: 'Bedrijven',
            url: '/bedrijven',
            icon: 'business-outline',
            hide: false
        },
        {
            title: 'Objecten',
            url: '/object',
            icon: 'albums-outline',
            hide: false
        },
        {
            title: 'Orders',
            url: '/orders',
            icon: 'clipboard-outline',
            hide: false
        },
        {
            title: 'Uren',
            url: '/uren',
            icon: 'time-outline',
            hide: false
        },
        {
            title: 'Instellingen',
            url: '/settings',
            icon: 'settings-outline',
            hide: false
        },
        {
            title: 'About',
            url: '/about',
            icon: 'information-circle',
            hide: false
        },
        {
            title: 'Logout',
            url: '/login',
            icon: 'exit',
            hide: false
        }
    ];
    // voor icons zie https://ionicons.com/

    constructor(
        private platform: Platform,
        public global: GlobalService
    ) {
        this.initializeApp();
    }

    initializeApp() {
        this.platform.ready().then(() => {
        });
    }
}
